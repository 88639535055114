import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getApiErrorMessage } from '@/lib/utils/Utils';
import { SelectValueDefault } from '@/constants';

import store from '@/admin/store';
import {
    createTariff,
    updateTariff,
    getTariff,
    removeTariff,
} from '@/admin/api/tariffs';

import SiteModule from '@/admin/store/site';

export const MODULE_NAME = 'tariffsEdit';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TariffsEdit extends EntityBaseModule {
    model: any;

    constructor(module: TariffsEdit) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_TITLE(value: string) {
        this.model.title.value = value;
    }

    @Mutation
    SET_DESCRIPTION(value: string) {
        this.model.description.value = value;
    }

    @Mutation
    SET_MONTHLY(value: string) {
        this.model.monthly.value = value;
    }

    @Mutation
    SET_WEEKLY(value: string) {
        this.model.weekly.value = value;
    }

    @Mutation
    SET_DAILY(value: string) {
        this.model.daily.value = value;
    }

    @Mutation
    SET_ANNUAL(value: string) {
        this.model.annual.value = value;
    }

    @Mutation
    SET_MONTHLY_DESCRIPTION(value: string) {
        this.model.monthlyDescription.value = value;
    }

    @Mutation
    SET_WEEKLY_DESCRIPTION(value: string) {
        this.model.weeklyDescription.value = value;
    }

    @Mutation
    SET_DAILY_DESCRIPTION(value: string) {
        this.model.dailyDescription.value = value;
    }

    @Mutation
    SET_ANNUAL_DESCRIPTION(value: string) {
        this.model.annualDescription.value = value;
    }

    @Mutation
    SET_ALLOW_TO_BUY(bool: boolean) {
        this.model.allowToBuy.list[0].checked = bool;
    }

    @Mutation
    SET_NUMBER_LOTS_VIEWS(value: string) {
        this.model.numberLotViews.value = value;
    }

    @Mutation
    SET_DATE_DEPTH(value: string) {
        this.model.dateDepth.value = value;
    }
    
    @Mutation
    SET_SORT(value: any) {
        this.model.sort.value = value;
    }

    @Action({ rawError: true })
    async initCardById(categoryId: number | string) {
        const result = await getTariff(categoryId);

        this.setInfo(result);
    }

    @Action({ rawError: true })
    resetError(field: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', field);
    }

    @Action({ rawError: true })
    setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_TITLE', data.title);
        this.context.commit('SET_DESCRIPTION', data.description);
        this.context.commit('SET_ALLOW_TO_BUY', data.allowToBuy);
        this.context.commit('SET_MONTHLY', data.tariffPlan.monthly || null);
        this.context.commit('SET_WEEKLY', data.tariffPlan.weekly || null);
        this.context.commit('SET_DAILY', data.tariffPlan.daily || null);
        this.context.commit('SET_ANNUAL', data.tariffPlan.annual || null);
        this.context.commit('SET_MONTHLY_DESCRIPTION', data.tariffPlan.monthlyDescription);
        this.context.commit('SET_WEEKLY_DESCRIPTION', data.tariffPlan.weeklyDescription);
        this.context.commit('SET_DAILY_DESCRIPTION', data.tariffPlan.dailyDescription);
        this.context.commit('SET_ANNUAL_DESCRIPTION', data.tariffPlan.annualDescription);
        this.context.commit('SET_DATE_DEPTH', data.dateDepth / 86400);
        this.context.commit('SET_NUMBER_LOTS_VIEWS', +data.permissions?.[0]?.access?.value || 50);
        this.context.commit('SET_SORT', data.sort);

        this.resetError('title');
        this.resetError('description');
        this.resetError('allowToBuy');
        this.resetError('monthly');
        this.resetError('weekly');
        this.resetError('daily');
        this.resetError('annual');
        this.resetError('monthlyDescription');
        this.resetError('weeklyDescription');
        this.resetError('dailyDescription');
        this.resetError('annualDescription');
        this.resetError('numberLotViews');
        this.resetError('dateDepth');
        this.resetError('sort');
    }

    @Action({ rawError: true })
    updateAllowToBuy(bool: boolean) {
        this.context.commit('SET_ALLOW_TO_BUY', bool);
    }

    @Action({ rawError: true })
    clearSort() {
        this.updateSuggestionsSelectedByType({ type: 'sort', selected: SelectValueDefault });
    }
    
    @Action({ rawError: true })
    async remove(id: string) {
        try {
            await removeTariff(id);

            ResponseHandlerModule.showNotify({ message: 'Успешно', type: 'ok' });
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    prepareRequestData() {
        const data: any = {
            title: this.model.title.value,
            description: this.model.description.value,
            monthly: this.model.monthly.value || 0,
            weekly: this.model.weekly.value || 0,
            daily: this.model.daily.value || 0,
            annual: this.model.annual.value || 0,
            monthlyDescription: this.model.monthlyDescription.value,
            weeklyDescription: this.model.weeklyDescription.value,
            dailyDescription: this.model.dailyDescription.value,
            annualDescription: this.model.annualDescription.value,
            dateDepth: +this.model.dateDepth.value * 86400,
            allowToBuy: this.model.allowToBuy.list[0].checked ? 1 : 0,
            sort: +this.model.sort.value,
            'permissions[0][key]': 'NUMBER_LOT_VIEWS',
            'permissions[0][value]': 50,
        };

        return data;
    }

    @Action({ rawError: true })
    validate() {
        const errors = [];

        if (!this.model.title.value?.length) {
            errors.push('title');
        }

        if (!this.model.description.value?.length) {
            errors.push('description');
        }

        if (!this.model.numberLotViews.value) {
            errors.push('numberLotViews');
        }

        if (!this.model.dateDepth.value) {
            errors.push('dateDepth');
        }

        if (!this.model.sort.value) {
            errors.push('sort');
        }

        if (!errors.length) {
            return true;
        }

        errors.forEach((item: string) => {
            this.setValidateState({ index: item, field: this.model[item] });
        });

        return false;
    }

    @Action({ rawError: true })
    async create() {
        const valid = await this.validate();

        if (!valid) {
            return false;
        }

        try {
            await createTariff(await this.prepareRequestData());

            ResponseHandlerModule.showNotify({ message: 'Успешно создано', type: 'ok' });

            return true;
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });

            return false;
        }
    }

    @Action({ rawError: true })
    async edit(id: number) {
        const valid = await this.validate();

        if (!valid) {
            return false;
        }

        try {
            await updateTariff(id, await this.prepareRequestData());

            ResponseHandlerModule.showNotify({ message: 'Успешно сохранено', type: 'ok' });

            return true;
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });

            return false;
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_TITLE', null);
        this.context.commit('SET_DESCRIPTION', '');
        this.context.commit('SET_ALLOW_TO_BUY', true);
        this.context.commit('SET_MONTHLY', '');
        this.context.commit('SET_WEEKLY', '');
        this.context.commit('SET_DAILY', '');
        this.context.commit('SET_ANNUAL', '');
        this.context.commit('SET_MONTHLY_DESCRIPTION', '');
        this.context.commit('SET_WEEKLY_DESCRIPTION', '');
        this.context.commit('SET_DAILY_DESCRIPTION', '');
        this.context.commit('SET_ANNUAL_DESCRIPTION', '');
        this.context.commit('SET_DATE_DEPTH', '');
        this.context.commit('SET_NUMBER_LOTS_VIEWS', 50);
        this.context.commit('SET_SORT', '');

        this.resetError('title');
        this.resetError('description');
        this.resetError('monthly');
        this.resetError('weekly');
        this.resetError('daily');
        this.resetError('annual');
        this.resetError('monthlyDescription');
        this.resetError('weeklyDescription');
        this.resetError('dailyDescription');
        this.resetError('annualDescription');
        this.resetError('numberLotViews');
        this.resetError('dateDepth');
        this.resetError('sort');

        SiteModule.SET_IS_BLOCK(false);
    }
}

export default getModule(TariffsEdit);
