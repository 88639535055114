import InputEntity from '@/lib/formFactory/inputEntity';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const titleEntity = new InputEntity('title');
        titleEntity.setTitle('Название');
        titleEntity.setRequired(true);

        const descriptionEntity = new InputEntity('description');
        descriptionEntity.setTitle('Описание');
        descriptionEntity.setPlaceholder('Новая строка равна буллету в публичном сайте');
        descriptionEntity.setRequired(true);

        const allowToBuyEntity = new CheckboxEntity('allowToBuy');
        allowToBuyEntity.setList({
            0: {
                id: 1,
                name: 'Тариф доступен к покупке',
                checked: true,
            },
        });

        const monthlyEntity = new InputEntity('monthly');
        monthlyEntity.setTitle('Ежемесячная цена');

        const weeklyEntity = new InputEntity('weekly');
        weeklyEntity.setTitle('Еженедельная цена');

        const dailyEntity = new InputEntity('daily');
        dailyEntity.setTitle('Ежедневная цена');

        const annualEntity = new InputEntity('annual');
        annualEntity.setTitle('Ежегодная цена');

        const monthlyDescriptionEntity = new InputEntity('monthlyDescription');
        monthlyDescriptionEntity.setTitle('Описание');

        const weeklyDescriptionEntity = new InputEntity('weeklyDescription');
        weeklyDescriptionEntity.setTitle('Описание');

        const dailyDescriptionEntity = new InputEntity('dailyDescription');
        dailyDescriptionEntity.setTitle('Описание');

        const annualDescriptionEntity = new InputEntity('annualDescription');
        annualDescriptionEntity.setTitle('Описание');

        const numberLotViewsEntity = new InputEntity('numberLotViews');
        numberLotViewsEntity.setTitle('Количество просмотров лота');
        numberLotViewsEntity.setValue('50');
        numberLotViewsEntity.setRequired(true);

        const dateDepthEntity = new InputEntity('dateDepth');
        dateDepthEntity.setTitle('Количество дней видимости лотов');
        dateDepthEntity.setRequired(true);

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Сортировка');
        sortEntity.setRequired(true);

        this.model = {
            title: titleEntity.entity[titleEntity.entityName],
            description: descriptionEntity.entity[descriptionEntity.entityName],
            allowToBuy: allowToBuyEntity.entity[allowToBuyEntity.entityName],
            monthly: monthlyEntity.entity[monthlyEntity.entityName],
            weekly: weeklyEntity.entity[weeklyEntity.entityName],
            daily: dailyEntity.entity[dailyEntity.entityName],
            annual: annualEntity.entity[annualEntity.entityName],
            monthlyDescription: monthlyDescriptionEntity.entity[monthlyDescriptionEntity.entityName],
            weeklyDescription: weeklyDescriptionEntity.entity[weeklyDescriptionEntity.entityName],
            dailyDescription: dailyDescriptionEntity.entity[dailyDescriptionEntity.entityName],
            annualDescription: annualDescriptionEntity.entity[annualDescriptionEntity.entityName],
            numberLotViews: numberLotViewsEntity.entity[numberLotViewsEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            dateDepth: dateDepthEntity.entity[dateDepthEntity.entityName],
        };
    }
}
