
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TariffsEditModule from '@/admin/store/tariffs/entity';
import InputBlock from '@/components/form/InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import Inputmask from 'inputmask';
import { numberRegExp } from '@/constants';
import moment from 'moment';
require('moment-precise-range-plugin');

@Component({
    components: {
        Layout,
        TitleReturn,
        InputBlock,
        TextareaBlock,
        BtnDefault,
        BtnSubmit,
        CheckboxCustom,
        SelectCustom,
    },
})
export default class TariffsEdit extends Vue {
    get model() {
        return TariffsEditModule.model;
    }

    get typeAction(): string {
        return this.$route.params.id === 'create' ? 'create' : 'edit';
    }

    get diffDate() {
        const diff = moment().subtract(+this.model.dateDepth.value, 'days');

        const result = moment().preciseDiff(diff);

        if (!result) {
            return null;
        }

        return result
            .replace(' days', ' дн.')
            .replace(' day', ' дн. ')
            .replace(' months', ' мес.')
            .replace(' month', ' мес.')
            .replace(' years', ' г.')
            .replace(' year', ' г.')
            .replace(' hours', 'ч.')
            .replace(' hour', 'ч.')
            .replace(' minutes', 'мин.')
            .replace(' minute', 'мин.');
    }

    updateTitle(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'title', value });

        TariffsEditModule.resetError('title');
    }

    updateDescription(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'description', value });

        TariffsEditModule.resetError('description');
    }

    updateMonthly(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'monthly', value });

        TariffsEditModule.resetError('monthly');
    }

    updateMonthlyDescription(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'monthlyDescription', value });

        TariffsEditModule.resetError('monthlyDescription');
    }

    updateWeekly(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'weekly', value });

        TariffsEditModule.resetError('weekly');
    }

    updateWeeklyDescription(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'weeklyDescription', value });

        TariffsEditModule.resetError('weeklyDescription');
    }

    updateDaily(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'daily', value });

        TariffsEditModule.resetError('daily');
    }

    updateAnnual(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'annual', value });

        TariffsEditModule.resetError('annual');
    }

    updateAnnualDescription(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'annualDescription', value });

        TariffsEditModule.resetError('annualDescription');
    }

    updateDailyDescription(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'dailyDescription', value });

        TariffsEditModule.resetError('dailyDescription');
    }

    updateNumberLotViews(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'numberLotViews', value });

        TariffsEditModule.resetError('numberLotViews');
    }

    updateDateDepth(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'dateDepth', value });

        TariffsEditModule.resetError('dateDepth');
    }

    updateSort(value: string) {
        TariffsEditModule.updateModelFieldValue({ field: 'sort', value });

        TariffsEditModule.resetError('sort');
    }

    updateAllowToBuy(params: { name: string; id: number; bool: boolean }): void {
        TariffsEditModule.updateAllowToBuy(params.bool);
    }

    initMask(): void {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async send(): Promise<void> {
        if (this.typeAction === 'create') {
            const result = await TariffsEditModule.create();

            if (!result) {
                return;
            }

            this.returnBefore();

            return;
        }

        const result = await TariffsEditModule.edit(+this.$route.params.id);

        if (!result) {
            return;
        }

        this.returnBefore();
    }

    async remove(): Promise<void> {
        await TariffsEditModule.remove(this.$route.params.id);

        this.returnBefore();
    }

    returnBefore(): void {
        this.$router.push({ name: 'tariffs' });
    }

    async mounted(): Promise<void> {
        this.initMask();

        if (this.typeAction === 'edit') {
            await TariffsEditModule.initCardById(this.$route.params.id);

            return;
        }

        TariffsEditModule.reset();
    }
}
